import { Router } from "@reach/router"
import { navigate } from "gatsby"
import React from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import SEO from "../../components/seo"
// import Layout from "../../../components/templates/layout"
import Layout from "../../components/layout/store/Layout"
import ClientView from "../../components/views/dashboard/store/clients"

const ClientsPage = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Layout>
        <ClientView />
      </Layout>
    </DndProvider>
  )
}

export default ClientsPage
